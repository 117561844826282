.nonebg {
    --background: none;
    background-image: url(/assets/background.png) !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.none {
    --ion-background-color: none !important
}
